<template>
    <div>
      <v-layout wrap justify-center fill-height>
        <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
          <v-navigation-drawer
            class="fixedSidebar"
            v-model="sideNav"
            clipped
            width="100%"
            height="100vh"
            permanent
            color="#202020"
          >
            <v-layout wrap justify-center>
              <template v-for="(item, i) in navItems">
                <v-flex
                  xs12
                  :key="i"
                  text-center
                  py-2
                  text-uppercase
                  align-self-center
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-link :to="item.route">
                        <span
                          :style="
                            $route.path == item.route
                              ? {
                                  'font-family': 'poppinsbold',
                                }
                              : {
                                  'font-family': 'poppinsthin',
                                }
                          "
                          style="
                            font-size: 15px;
                            color: #ffffff;
                            letter-spacing: 3px;
                            cursor: pointer;
                          "
                        >
                          {{ item.name }}
                        </span>
                      </router-link>
                    </v-flex>
                    <v-flex xs12 pt-2 px-4>
                      <div style="border-bottom: 1px solid #ffffff25"></div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </template>
            </v-layout>
          </v-navigation-drawer>
        </v-flex>
        <v-flex xs12 sm12 md12 lg10 pt-8 pt-md-0>
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        sideNav: true,
        navItems: [
          { name: "ANALYTICS", route: "/" },
          { name: "ADD A PRODUCT", route: "/AddProduct" },
          { name: "PRODUCT SOLD", route: "/SoldProducts" },
          { name: "PENDING ORDERS", route: "/PendingOrders" },
          { name: "ACCEPTED ORDERS", route: "/AcceptedOrders" },
          { name: "SHIPPED ORDERS", route: "/ShippingOrders" },
          { name: "CANCELLED ORDERS", route: "/CancelledOrders" },
          { name: "INVENTORY", route: "/Inventory" },
          { name: "ORDER REPORTS", route: "/OrderReports" },
          { name: "ITEM WISE REPORT", route: "/ItemWiseReports" },
          //{ name: "OFFERS", route: "/Offers" },
          { name: "PRODUCT REVIEWS", route: "/ProductReviews" },
          { name: "SELLER REVIEWS", route: "/SellerReviews" },
          { name: "PROMOCODE", route: "/Promocodes" },
          // { name: "TRIAL ROOM", route: "/TrialRooms" },
          // { name: "GALLERY", route: "/Gallery" },
          { name: "PROFILE", route: "/Profile" },
        ],
      };
    },
    beforeMount() {},
    methods: {
      changeRoute(item) {
        if (this.$route.query.styl != item._id) {
          this.$router.push({
            path: "/Products",
            query: {
              styl: item._id,
              category: this.$route.query.category,
              subcategory: this.$route.query.subcategory,
              searchKey: this.$route.query.searchKey,
            },
          });
        } else {
          this.$router.push({
            path: "/Products",
            query: {
              styl: null,
              category: this.$route.query.category,
              subcategory: this.$route.query.subcategory,
              searchKey: this.$route.query.searchKey,
            },
          });
        }
      },
    },
  };
  </script>
  <style>
  @media only screen and (min-device-width: 767px) {
    .fixedSidebar {
      /* position: fixed; */
  
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 50;
    }
  }
  @media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
    .fixedSidebar {
      display: none !important;
    }
  }
  </style>